<template>
  <v-container grid-list-md pa-0 relative class="aio">
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card class="primary--border" outlined>
          <v-card-title style="border-bottom:1px solid #ccc" class="title">
            All in one search
            <v-spacer></v-spacer>
            <a
              href=""
              @click.prevent="hide_search = !hide_search"
              style="text-decoration: none"
            >
              <v-icon v-if="hide_search">search</v-icon>
              <v-icon v-else>close</v-icon>
            </a>
          </v-card-title>
          <v-card v-if="!hide_search" outlined>
            <v-card-title
              style="padding-bottom: 0; overflow: hidden"
              :class="`title ${hide_search ? 'hide_search' : ''}`"
            >
              <v-flex>
                <div class="search-box">
                  <v-layout row wrap>
                    <v-flex xs10>
                      <v-autocomplete
                        v-model="friends"
                        :disabled="isUpdating"
                        :items="people"
                        :loading="isLoading"
                        :search-input.sync="search"
                        @change="selectedUser"
                        hide-no-data
                        hide-selected
                        outlined
                        dense
                        label="Start typing user's name"
                        color="blue-grey"
                        placeholder="Start typing to Search"
                        item-text="name"
                        prepend-icon="search"
                        item-value="name"
                        return-object
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            :selected="data.selected"
                            close
                            class="chip--select-multi"
                            @input="remove(data.item)"
                          >
                            <v-avatar>
                              <img :src="data.item.avatar" />
                            </v-avatar>
                            {{ data.item.name }}
                          </v-chip>
                        </template>
                        <template
                          @click="selectedUser(data)"
                          v-slot:item="data"
                        >
                          <template v-if="typeof data.item !== 'object'">
                            <v-list-tile-content
                              v-text="data.item"
                            ></v-list-tile-content>
                          </template>
                          <template v-else>
                            <v-list-tile-avatar>
                              <img :src="data.item.avatar" />
                            </v-list-tile-avatar>
                            <v-list-tile-content>
                              <v-list-tile-title
                                v-html="data.item.name"
                              ></v-list-tile-title>
                              <v-list-tile-sub-title
                                v-html="data.item.group"
                              ></v-list-tile-sub-title>
                            </v-list-tile-content>
                          </template>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                    <v-flex xs2>
                      <v-btn text outlined>Clear Search</v-btn>
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>
            </v-card-title>
          </v-card>
          <div class="search-content-holder">
            <div
              v-if="Object.keys(selectedUserData).length"
              class="details_section"
            >
              <PersonalSideBar
                :selected-user-data="selectedUserData"
              ></PersonalSideBar>

              <div class="user_detail_info">
                <v-tabs
                  centered
                  color="white"
                  v-model="selectedTab"
                  dark
                  icons-and-text
                  grow
                >
                  <v-tabs-slider color="#1976d2"></v-tabs-slider>

                  <v-tab style="margin-left: 0;" href="#tab-1" value="academic">
                    <v-icon>school</v-icon> &nbsp; Academics
                  </v-tab>

                  <v-tab href="#tab-2" value="exam">
                    <v-icon>event_note</v-icon> &nbsp; Examinations
                  </v-tab>

                  <v-tab href="#tab-3" value="billing">
                    <v-icon>account_box</v-icon> &nbsp; Billings
                  </v-tab>

                  <v-tab href="#tab-4" value="transport">
                    <v-icon>emoji_transportation</v-icon> &nbsp; Transport
                  </v-tab>

                  <v-tab-item value="tab-1">
                    <v-card flat>
                      <div class="tab_box">
                        <div class="tab_title">
                          <p>Batch List</p>
                        </div>
                        <div
                          :key="key"
                          class="batch_lists_container"
                          v-for="(record,
                          key) in selectedUserData.batch_records"
                        >
                          <!--{{record}}-->
                          <div
                            :class="
                              'batch_list ' +
                                (record.is_current ? 'active' : '')
                            "
                          >
                            <p>
                              <small class="light_text font-weight-bold">
                                {{ record.academic.batch_start }}
                                &nbsp;&nbsp;TO&nbsp;&nbsp;
                                {{ record.academic.batch_end }}
                              </small>
                            </p>
                            <h3>{{ record.academic.batch }}</h3>

                            <div class="academic_record">
                              <p>
                                <v-icon>play_arrow</v-icon>
                                {{ record.academic.grade }} ({{
                                  record.academic.section
                                }})
                                <span class="roll_no">
                                  <strong v-if="key === 0"
                                    >ADMISSION DATE
                                    {{
                                      selectedUserData.admission_date
                                    }}</strong
                                  >
                                  / ROLL NO. {{ record.roll }}</span
                                >
                              </p>
                            </div>
                          </div>
                        </div>

                        <br />
                        <div class="tab_title">
                          <p>Academic Reports</p>
                        </div>

                        <div class="academic_report">
                          <div class="assignment_rep report_box active">
                            <div class="chart_box"></div>
                            <div class="chart_info">
                              <h4>20/400</h4>
                              <p>Assignment this month.</p>
                            </div>
                          </div>
                          <div class="attendance_rep report_box">
                            <div class="chart_box"></div>
                            <div class="chart_info">
                              <h4>26/32</h4>
                              <p>Attendance this month.</p>
                            </div>
                          </div>
                          <div class="subject_rep report_box">
                            <div class="chart_box"></div>
                            <div class="chart_info">
                              <h4>6/2</h4>
                              <p>Cum/Opt Subjects.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item value="tab-2">
                    <v-card flat>
                      <div class="tab_box" style="padding: 0;">
                        <AllExamIndividual
                          :enroll-id="selectedUserData.enroll_id"
                        ></AllExamIndividual>
                        <!--<v-autocomplete-->
                        <!--:items="examList"-->
                        <!--hide-no-data-->
                        <!--@change="selectExam"-->
                        <!--hide-selected-->
                        <!--box-->
                        <!--label="Select Exam"-->
                        <!--color="blue-grey"-->
                        <!--placeholder="Click to select"-->
                        <!--item-text="name"-->
                        <!--item-value="name"-->
                        <!--return-object>-->
                        <!--<template v-slot:selection="data">-->
                        <!--<v-chip-->
                        <!--color="#1976d2"-->
                        <!--:selected="data.selected"-->
                        <!--close-->
                        <!--class="chip&#45;&#45;select-multi"-->
                        <!--@input="remove(data.item)">-->
                        <!--<span style="color:white;">{{ data.item.name }}</span>-->
                        <!--</v-chip>-->
                        <!--</template>-->
                        <!--<template @click="selectedChildren(data)"-->
                        <!--v-slot:item="data">-->
                        <!--<template v-if="typeof data.item !== 'object'">-->
                        <!--<v-list-tile-content-->
                        <!--v-text="data.item"></v-list-tile-content>-->
                        <!--</template>-->
                        <!--<template v-else>-->
                        <!--<v-list-tile-content>-->
                        <!--<v-list-tile-title-->
                        <!--v-html="data.item.name"></v-list-tile-title>-->
                        <!--<v-list-tile-sub-title-->
                        <!--v-html="data.item.group"></v-list-tile-sub-title>-->
                        <!--</v-list-tile-content>-->
                        <!--</template>-->
                        <!--</template>-->
                        <!--</v-autocomplete>-->
                        <!--</div>-->
                      </div>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </div>
            </div>
            <div v-else class="no-info-alert">
              <div class="search-result">
                <search-result></search-result>
              </div>
              <h1>No Search Results</h1>
              <p>
                Search and select user in order to view his/her AIO Results.
              </p>
            </div>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import SearchResult from "./search";
import PersonalSideBar from "./modules/personalsidebar";
import AllExamIndividual from "./modules/allexamindividual";

let typingTimer;
let doneTypingInterval = 300;

export default {
  components: { SearchResult, PersonalSideBar, AllExamIndividual },
  data() {
    const srcs = {
      1: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      2: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
      3: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
      4: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
      5: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
    };
    return {
      selectedTab: "tab-2",
      series: [
        {
          name: "series1",
          data: [31, 40, 28, 51],
        },
      ],
      chartOptions: {
        chart: {
          height: 300,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          categories: ["First", "Second", "Third", "Final"],
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
      },
      hide_search: "",
      // isLoading: false,
      items: [
        {
          action: "person",
          title: "Personal",
          sub_title: "DOB/Gender/Religion",
          active: false,
          items: [
            { title: "Breakfast & brunch" },
            { title: "New American" },
            { title: "Sushi" },
          ],
        },
        // {
        //     action: 'supervised_user_circle',
        //     title: 'Guardian',
        //     sub_title: 'Guardian/Mother/Father/Contact',
        //     items: [
        //         {title: 'List Item'}
        //     ]
        // },
        // {
        //     action: 'person',
        //     title: 'Address',
        //     sub_title: 'Permanent/Temporary',
        //     items: [
        //         {title: 'List Item'}
        //     ]
        // }
      ],
      isLoading: false,
      search: "",
      autoUpdate: true,
      friends: ["Sandra Adams", "Britta Holt"],
      isUpdating: false,
      name: "Midnight Crew",
      selectedUserData: {},
      people: [],
      title: "The summer breeze",
      examList: [],
    };
  },
  computed: {
    ...mapState(["batch"]),
  },
  watch: {
    pagination: function() {
      this.get();
    },
    batch: function(value) {
      // this.get();
    },
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
    search(val) {
      if (!val) return;

      if (val.length < 1) return;

      // Items have already been requested
      if (this.isLoading) return;

      let $this = this;
      clearTimeout(typingTimer);
      typingTimer = setTimeout(function() {
        $this.searchStudentApi(val);
      }, doneTypingInterval);
    },
  },
  mounted() {
    // this.selectedUser();
    this.fetchExams();
  },
  methods: {
    ...mapActions(["getStudentInfo"]),

    fetchExams() {
      this.$rest.get("/api/exam?slim=true").then(({ data }) => {
        this.examList = data.data;
      });
    },
    selectExam(item) {
      this.$rest.get("/api/report/exam/marksheet", {}).then(() => {});
    },
    searchStudentApi(val) {
      if (!val) return;
      this.isLoading = true;
      this.$rest
        .get(
          "api/search-student?rowsPerPage=10&page=1&sortBy=name&rowsPerPage=10&search=" +
            val.trim()
        )
        .then(({ data }) => {
          this.people = data.data.map(function(item) {
            return {
              name: item.name,
              avatar: item.profile_image,
              details: item,
            };
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    remove(item) {
      this.selectedUserData = {};
    },
    selectedUser(selectedItem) {
      this.getStudentInfo("/api/student/" + selectedItem)
        .then((res) => {
          // this.getStudentInfo('/api/student/' + 330).then(res => {
          this.selectedUserData = res;
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            this.$events.fire("notification", {
              message: "Students not found",
              status: "error",
            });
            this.$router.push({
              name: "search-students",
            });
          }
        });
    },
  },
};
</script>
<style lang="scss">
.light_text {
  color: #999;
}

.no-info-alert {
  /*background: red;*/
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  h1 {
    color: #666;
    + p {
      color: #999;
    }
  }

  align-items: center;
  .search-result {
  }
}

.search-content-holder {
  min-height: 450px;
  display: flex;
  .user_personal_info {
    flex: 3;
    overflow: hidden;
    padding: 20px 0;
    /*background-color: green;*/
    .content {
      height: 100%;
      flex: 1;
      border-right: 1px solid #e3e3e3;
      padding: 0 15px;
      .profile_head {
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px dashed #e0e0e0;
        display: flex;
        align-items: center;
        flex-direction: column;
        color: #666;
        font-size: 13px;
        img {
          border: 5px solid #eee;
          height: 120px;
          width: 120px;
          border-radius: 150px;
        }
        h1 {
          font-size: 23px;
          margin-top: 15px;
          font-weight: normal;
          line-height: 1;
        }
        p {
          color: #888;
          line-height: 1.5;
        }
        .special_info {
          display: flex;
          width: 97%;
          margin-top: 15px;
          .info {
            background-color: transparent !important;
            background: none;
            width: 33%;
            border-right: 3px solid #eee !important;
            padding-left: 10px;
            padding-right: 10px;
            margin-right: 10px;
            display: block;
            &:last-child {
              border-right: none !important;
            }
            small {
              font-size: 10px;
              color: #ccc;
              font-weight: bold;
            }
            p {
              font-weight: bold;
              color: #777;
              font-size: 16px;
              margin-top: 0;
              line-height: 1;
              margin-bottom: 0;
            }
          }
        }
        .progress-profile {
          h4 {
            font-size: 12px;
            margin-bottom: 0;
            line-height: 1.5;
          }
          .v-progress-linear {
            margin-top: 5px;
            height: 5px !important;
          }
          margin-top: 15px;
          width: 90%;
        }
      }
      .other_personal_info {
        .v-list__group__header__prepend-icon {
          padding: 0;
        }
        .v-list__group__header__prepend-icon {
          .v-icon {
            color: #1976d2 !important;
          }
        }
        padding: 0 15px;
        color: #777;
        h4 {
          color: #999;
        }
        .v-list__tile {
          padding: 0 !important;
        }
      }
    }
    .v-list__group--active {
      .theme--light.v-list {
        background: rgba(0, 0, 0, 0.04) !important;
      }
    }
    .theme--light.v-list .v-list__group__header:hover,
    .theme--light.v-list .v-list__tile--highlighted,
    .theme--light.v-list .v-list__tile--link:hover {
      background: none !important;
    }
    .theme--light.v-list .v-list__group--active:after,
    .theme--light.v-list .v-list__group--active:before {
      background: none !important;
    }
    .v-list__group__header .v-list__group__header__prepend-icon {
      min-width: 45px;
    }
    .v-list__tile__sub-title {
      font-size: 12px;
    }
  }
  .user_detail_info {
    flex: 6.5;
    padding: 0 20px;
    .v-tabs {
      a {
        color: #333 !important;
      }
      .theme--dark.v-icon {
        color: #333;
      }

      a.v-tabs__item.v-tabs__item--active {
        color: #1976d2 !important;
        font-weight: bold;
        background: #e3effb;
        border-bottom: 3px solid #1976d2;
        .theme--dark.v-icon {
          color: #1976d2 !important;
        }
      }
    }

    .v-tabs__container--icons-and-text .v-tabs__item {
      flex-direction: row;
    }
    .v-tabs__item {
      justify-content: center;
    }
    .v-tabs__container {
      border-bottom: 1px solid #ccc;
    }
    .v-tabs__item {
      padding: 6px 0;
    }
    .v-tabs__container--grow .v-tabs__div {
      flex: none;
    }
    .v-tabs__container--icons-and-text {
      height: 50px;
      background-color: #f6f6f6;
      border-radius: 6px;
      overflow: hidden;
    }

    /*background-color: orange;*/
  }

  .tab_box {
    /*background-color: red;*/
    padding: 15px;
    margin-top: 5px;
    .tab_title {
      font-weight: bold;
      color: #ccc;
      p {
        margin-bottom: 0;
      }
    }

    .batch_lists_container {
      .batch_list {
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
        padding: 5px 10px;
        border-radius: 2px;
        margin-bottom: 20px;
        margin-top: 10px;
        &.active {
          border: 1px solid #b4d3f1;
          box-shadow: 0 3px 15px 2px rgba(0, 0, 0, 0.1);
        }
        p {
          margin-bottom: 0;
          line-height: 1.3;
        }
        h3 {
          margin-top: 0;
          color: #666;
          border-bottom: 1px dashed #eee;
          margin-bottom: 10px;
          padding-bottom: 10px;
          i {
            color: #1976d2 !important;
            font-size: 18px;
          }
        }
        .academic_record {
          margin-top: 5px;
          /*border-left: 2px solid #999;*/
          padding: 2px 0;
          font-size: 13px;
          margin-bottom: 8px;
          color: #999;
          font-weight: bold;
          margin-left: -7px;
          line-height: 1;
          i {
            color: #999;
            font-size: 16px;
            line-height: 0.9;
          }
          span {
            float: right;
            font-weight: normal;
            font-size: 12px;
            /*text-align: right;*/
          }
        }
      }
    }

    .academic_report {
      display: flex;
      margin-top: 15px;
      .report_box {
        cursor: pointer;
        min-height: 80px;
        background-color: white;
        border-right: 2px;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        flex: 1;
        display: flex;
        align-items: center;
        &.active {
          border-bottom: 5px solid #b4d3f1;
        }
        .chart_box {
          height: 50px;
          margin: 0 10px 0 8px;
          width: 50px;
          border-radius: 50%;
        }
        .chart_info {
          /*background-color: red;*/
          height: 30px;
          h4 {
            margin-bottom: 0;
            color: #666;
            line-height: 1;
          }
          p {
            font-size: 12px;
            color: #999;
            margin-top: 0;
            /*font-weight: bold;*/
          }
        }
      }
      .assignment_rep {
        margin-right: 10px;
        .chart_box {
          background-color: #724a9f;
        }
      }

      .attendance_rep {
        margin-right: 10px;
        .chart_box {
          background-color: #1976d2 !important;
        }
      }

      .subject_rep {
        margin-right: 10px;
        .chart_box {
          background-color: #ff994f !important;
        }
      }

      .subject_rep {
        flex: 1;
      }
    }
  }
}

.hide_search {
  height: 0 !important;
}

.details_section {
  display: flex;
  flex: 1;
}

.page-layout {
  border-top: 10px solid #ccc;
  .v-card__title {
    font-weight: normal;
    color: #333;
    font-size: 18px !important;
    .head_icon.v-icon {
      font-size: 18px;
      background-color: #ccc;
      color: #333;
      border-radius: 20px;
      padding: 3px;
    }
  }
}

.aio {
  .v-btn:not(.v-btn--depressed):not(.v-btn--flat) {
    box-shadow: none;
  }
  .theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
    background-color: transparent;
  }
  .v-btn--large {
    font-weight: bold;
    font-size: 14px;
    color: #888;
    padding: 0;
  }
  .v-btn--active:before,
  .v-btn:focus:before,
  .v-btn:hover:before {
    background-color: transparent;
  }
  .search-content-holder {
    padding: 10px 20px;
  }

  .v-list__tile__action,
  .v-list__tile__avatar {
    min-width: 35px;
  }
  .v-list__tile__sub-title,
  .v-list__tile__title {
    white-space: normal;
  }
}
</style>
