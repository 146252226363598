<template>
    <div class="user_personal_info">
        <div class="content">

            <div class="profile_head">
                <img :src="selectedUserData.profile_image" alt=""/>
                <h1>{{selectedUserData.name}}</h1>
                <p style="text-align: center;">
                    {{selectedUserData.address.temporary.address}}</p>

                <div class="progress-profile">
                    <h4>PROFILE PROGRESS 37%</h4>
                    <v-progress-linear :rounded="true" value="37"></v-progress-linear>
                </div>

                <div class="special_info">
                    <div class="info">
                        <small>Grade</small>
                        <p>{{selectedUserData.grade}}</p>
                    </div>
                    <div class="info">
                        <small>Section</small>
                        <p>{{selectedUserData.section}}</p>
                    </div>
                    <div class="info">
                        <small>Roll No.</small>
                        <p>{{selectedUserData.roll}}</p>
                    </div>
                </div>
            </div>


            <div class="other_personal_info">
                <h4>Other Personal Information</h4>

                <v-list two-line>
                    <v-list-group
                            :prepend-icon="'person'"
                            no-action>

                        <template v-slot:activator>
                            <v-list-tile>
                                <v-list-tile-content>
                                    <v-list-tile-title>Personal</v-list-tile-title>
                                    <v-list-tile-sub-title>DOB/Gender/Religion
                                    </v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </template>

                        <v-list two-line>

                            <v-list-tile>
                                <v-list-tile-action>
                                    <!--<v-icon color="indigo">supervised_user_circle</v-icon>-->
                                </v-list-tile-action>
                                <v-list-tile-content>
                                    <v-list-tile-title>{{selectedUserData.personal.dob}}
                                    </v-list-tile-title>
                                    <v-list-tile-sub-title>Date of Birth
                                    </v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>

                            <v-list-tile>
                                <v-list-tile-action>
                                    <!--<v-icon color="indigo">supervised_user_circle</v-icon>-->
                                </v-list-tile-action>
                                <v-list-tile-content>
                                    <v-list-tile-title>
                                        {{selectedUserData.personal.gender.toUpperCase()}}
                                    </v-list-tile-title>
                                    <v-list-tile-sub-title>Gender
                                    </v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>

                            <v-list-tile>
                                <v-list-tile-action>
                                    <!--<v-icon color="indigo">supervised_user_circle</v-icon>-->
                                </v-list-tile-action>
                                <v-list-tile-content>
                                    <v-list-tile-title>{{selectedUserData.personal.religion}} /
                                        {{selectedUserData.personal.ethnicity}}
                                    </v-list-tile-title>
                                    <v-list-tile-sub-title>Religion/Ethnicity
                                    </v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-list>
                    </v-list-group>
                </v-list>

                <v-list two-line>
                    <v-list-group
                            :prepend-icon="'supervised_user_circle'"
                            no-action>
                        <template v-slot:activator>
                            <v-list-tile>
                                <v-list-tile-content>
                                    <v-list-tile-title>Guardian</v-list-tile-title>
                                    <v-list-tile-sub-title>Guardian/Mother/Father/Contact
                                    </v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </template>

                        <v-list two-line>
                            <v-list-tile>
                                <v-list-tile-action>
                                    <!--<v-icon color="indigo">supervised_user_circle</v-icon>-->
                                </v-list-tile-action>
                                <v-list-tile-content>
                                    <v-list-tile-title>{{selectedUserData.guardian.name}}
                                    </v-list-tile-title>
                                    <v-list-tile-sub-title>Guardian /
                                        {{selectedUserData.guardian.relation}}
                                    </v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>


                            <v-list-tile>
                                <v-list-tile-action>
                                    <!--<v-icon color="indigo">supervised_user_circle</v-icon>-->
                                </v-list-tile-action>
                                <v-list-tile-content>
                                    <v-list-tile-title>{{selectedUserData.guardian.mobile ||
                                        'N/A'}}
                                    </v-list-tile-title>
                                    <v-list-tile-sub-title>Contact
                                    </v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>

                            <v-list-tile>
                                <v-list-tile-action>
                                    <!--<v-icon color="indigo">supervised_user_circle</v-icon>-->
                                </v-list-tile-action>
                                <v-list-tile-content>
                                    <v-list-tile-title>
                                        {{selectedUserData.guardian.family.father}}
                                    </v-list-tile-title>
                                    <v-list-tile-sub-title>Father
                                    </v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>

                            <v-list-tile>
                                <v-list-tile-action>
                                    <!--<v-icon color="indigo">supervised_user_circle</v-icon>-->
                                </v-list-tile-action>
                                <v-list-tile-content>
                                    <v-list-tile-title>
                                        {{selectedUserData.guardian.family.mother}}
                                    </v-list-tile-title>
                                    <v-list-tile-sub-title>Mother
                                    </v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>

                            <v-list-tile>
                                <v-list-tile-action>
                                    <!--<v-icon color="indigo">supervised_user_circle</v-icon>-->
                                </v-list-tile-action>
                                <v-list-tile-content>
                                    <v-list-tile-title>
                                        {{selectedUserData.guardian.family.grand_father}}
                                    </v-list-tile-title>
                                    <v-list-tile-sub-title>Grand Father
                                    </v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-list>
                    </v-list-group>
                </v-list>


                <v-list two-line>
                    <v-list-group
                            :prepend-icon="'location_on'"
                            no-action>

                        <template v-slot:activator>
                            <v-list-tile>
                                <v-list-tile-content>
                                    <v-list-tile-title>Address</v-list-tile-title>
                                    <v-list-tile-sub-title>Permanent / Temporary
                                    </v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </template>

                        <v-list two-line>

                            <v-list-tile>
                                <v-list-tile-action>
                                    <!--<v-icon color="indigo">supervised_user_circle</v-icon>-->
                                </v-list-tile-action>
                                <v-list-tile-content>
                                    <v-list-tile-title>
                                        <div><p>
                                            {{selectedUserData.address.permanent.address}}</p>
                                        </div>
                                    </v-list-tile-title>
                                    <v-list-tile-sub-title>Permanent
                                    </v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>

                            <v-list-tile>
                                <v-list-tile-action>
                                    <!--<v-icon color="indigo">supervised_user_circle</v-icon>-->
                                </v-list-tile-action>
                                <v-list-tile-content>
                                    <v-list-tile-title>
                                        {{selectedUserData.address.temporary.address}}
                                    </v-list-tile-title>
                                    <v-list-tile-sub-title>Temporary
                                    </v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-list>
                    </v-list-group>
                </v-list>


            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PersonalSidebar",
        props: {
            selectedUserData: {
                required: true,
                type: Object
            }
        }
    }
</script>

<style scoped>

</style>